import { MantineTheme, MantineThemeOverride } from '@mantine/core'

import neueHaasGrotesk from 'url:../assets/NeueHaasGrotesk.ttf'

const fullSize = { width: '100%', height: '100%' }

// Sizes from Figma
  // Tap to start: 54px
  // Consent
    // Header: 80px
    // Body text: 28px
    // Enter button: 30px

  // Questions
    // Heading: 32px
    // Button: 20px
    // Progress number: 18px
    // Question text: 18px
    // Bullet options: 16px
    // Body text: 16px

  // Cooldown number
    // 18px

  // Ethical Boundaries
    // 32px

  // Reset button
    // 32px

  // Timeout screen
    // Heading: 70px
    // Countdown: 170px
    // Buttons: 30px


const theme: MantineThemeOverride = {
  primaryColor: 'brand',
  colors: {
    brand: ['#FFFFFF', '#F1F3F5', '#555555', '#DEE2E6', '#CED4DA', '#ADB5BD', '#000000', '#495057', '#343A40', '#000000'],
    dark: ['#FFFFFF','#A6A7AB','#909296','#5c5f66','#373A40','#2C2E33', '#25262b', '#1A1B1E','#141517', '#101113',],
  },
  primaryShade: {
    light: 6,
    dark: 1,
  },
  colorScheme: 'dark',
  fontFamily: 'Neue Haas Grotesk Display Pro, sans-serif',
  lineHeight: 1.4,
  fontSizes: {
    xs: 16,
    sm: 18,
    md: 20,
    lg: 28,
    xl: 32,
  },
  headings: {
    fontFamily: 'Neue Haas Grotesk Display Pro, sans-serif',
    fontWeight: 400,
    sizes: {
      h1: { fontSize: 180 },
      h2: { fontSize: 80 },
      h3: { fontSize: 32 },
    }
  },
  shadows: {
    xs: '0 1px 3px rgba(255, 255, 255, 0.05), 0 1px 2px rgba(255, 255, 255, 0.1)',
    sm: '0 1px 3px rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 0px 10px 15px -5px, rgba(255, 255, 255, 0.04) 0px 7px 7px -5px',
    md: '0 1px 3px rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 0px 20px 25px -5px, rgba(255, 255, 255, 0.04) 0px 10px 10px -5px',
    lg: '0 1px 3px rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 0px 28px 23px -7px, rgba(255, 255, 255, 0.04) 0px 12px 12px -7px',
    xl: '0 1px 3px rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 0px 36px 28px -7px, rgba(255, 255, 255, 0.04) 0px 17px 17px -7px',
  },
  components: {
    Textarea: {
      styles: {
        input: {
          backgroundColor: '#1D1D1D',
        },
      },
    },
    Button: {
      defaultProps: {
        uppercase: true,
        // Should just apply this to outline button: https://github.com/mantinedev/mantine/discussions/2004
        sx: (theme: MantineTheme) => ({
          color: theme.colorScheme === 'dark' ? theme.colors[theme.primaryColor][8] : theme.colors[theme.primaryColor][1]
        })
      },
    },
    Indicator: {
      styles: (theme) => ({
        indicator: {
          color: theme.colors[theme.primaryColor][9]
        },
      }),
    },
    Select: {
      styles: (theme) => ({
        item: {
          '&:hover': {
            backgroundColor: theme.colors[theme.primaryColor][3],
          },
          '&[data-selected]:hover': {
            color: theme.colors[theme.primaryColor][9],
            backgroundColor: theme.colors[theme.primaryColor][3],
          },
          '&[data-selected]': {
            color: theme.colors[theme.primaryColor][9],
            // borderColor: 'black',
            // border: '1px solid black',
            // borderRadius: '12px',
            // borderColor: theme.colors[theme.primaryColor][9],
            backgroundColor: theme.colors[theme.primaryColor][2],
          }
        },
      }),
    },
    SegmentedControl: {
      defaultProps: {
        color: 'dark.0',
        styles: (theme: MantineTheme) => ({
          label: {
            textTransform: 'uppercase',
            color: theme.colorScheme === 'dark' ? theme.colors[theme.primaryColor][0] : theme.colors[theme.primaryColor][7],
          },
          labelActive: {
            '&, &:hover': {
              color: 'black !important'
            },
          }

        }),
      },
    },
    Radio: {
      styles: {
        radio: {
          backgroundColor: '#1D1D1D',
          border: '2px solid white',
          '&:disabled': {
            visibility: 'hidden',
          },
          '&:checked:disabled': {
            visibility: 'inherit',
            backgroundColor: 'white',
            borderColor: 'white'
          }
        },
      }
    },
    Title: {
      defaultProps: {
        transform: 'uppercase',
      },
    },
  },
  activeStyles: {
    transform: '',
    backgroundColor: 'transparent',
    border: '2px solid white',
    color: 'white',
  },

  globalStyles: (theme: MantineTheme) => ({
    '*': {
      boxSizing: 'border-box',
    },
    'svg *': {
      vectorEffect: 'non-scaling-stroke',
    },
    '@font-face': {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      src: `url('${neueHaasGrotesk}') format("woff2")`,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    html: fullSize,
    body: {
      ...fullSize,
      margin: 0,
      userSelect: 'none',
      webkitUserSelect: 'none',
    },
    '#root': {
      width: '100%',
      height: '100%',
    },
    '.hg-button.hg-button-space': {
      flexGrow: 5,
    },
    th: {
      fontWeight: '400 !important' as any,
    },
  }),
}

export default theme
