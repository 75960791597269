import React from 'react'
import ReactDOM from 'react-dom/client'
import { MantineProvider } from '@mantine/core'

import { BrowserRouter, Routes, Route } from "react-router-dom";

import theme from './theme'

import Survey from './pages/Survey'
import Admin from './pages/Admin'
import Debug from './pages/Debug'
import Lock from './pages/Lock'


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Survey />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/debug" element={<Debug />} />
            <Route path="/lock" element={<Lock />} />
          </Routes>
      </BrowserRouter>
    </MantineProvider>
  </React.StrictMode>
)
