import { useCallback, useMemo, ChangeEvent, memo } from 'react'

import { TextInput, Table, Button, Text, Checkbox, createStyles } from '@mantine/core'
import { useQuestionStore, Question, QuestionId } from '../model/questions'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { IconGripVertical } from '@tabler/icons'


const useStyles = createStyles((theme) => ({
  item: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  dragHandle: {
    ...theme.fn.focusStyles(),
    cursor: 'grab',
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
  },
}))

type SubjectInputProps = {
  id: QuestionId,
  subject: string,
}

const SubjectInput = memo(({id, subject}: SubjectInputProps) => {
  const updateQuestion = useQuestionStore(state => state.updateQuestion)

  const onSubjectChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.target) updateQuestion(id, {subject: ev.target.value})
  }, [id])

  return (
    <TextInput
      sx={{width: 'auto'}}
      styles={{
        root: {
          width: 'auto',
        },
        input: {
          width: 'auto',
          // '&:active':{
          '&:not(:focus)':{
            border: 'none',
            background: 'none',
          },
        },
      }}
      value={subject}
      onChange={onSubjectChange}
    />
  )
})

type QuestionRowProps = {
  i: number,
  question: Question,
}

const QuestionRow = memo(({i, question}: QuestionRowProps) => {
  const {classes} = useStyles()

  const removeQuestion = useQuestionStore(state => state.removeQuestion)
  const updateQuestion = useQuestionStore(state => state.updateQuestion)
  const editQuestion = useQuestionStore(state => state.editQuestion)

  const {id, subject, enabled} = question

  const onRemove = useCallback(() => removeQuestion(id), [id])
  const onChecked = useCallback((ev: ChangeEvent<HTMLInputElement>) =>
    updateQuestion(id, {enabled: ev.currentTarget.checked})
  , [id])

  const onQuestionClick = useCallback(() =>
    editQuestion({question, originalId: id})
  , [id, question])

  return (
    <Draggable draggableId={question.id} index={i}>
    {(provided) =>
      <tr ref={provided.innerRef} {...provided.draggableProps}>
        <td>
          <div className={classes.dragHandle} {...provided.dragHandleProps}>
            <IconGripVertical size={18} stroke={1.5} />
          </div>
        </td>
        <td align="center">
          <Checkbox size="md" checked={enabled} onChange={onChecked} />
        </td>
        <td>
          <SubjectInput id={id} subject={subject} />
        </td>

        <td key={id}>
          <Text mt='6px' size='sm' onClick={onQuestionClick}>{question.question}</Text>
        </td>

        <td>
          <Button w="48px" onClick={onRemove}>-</Button>
        </td>

      </tr>
    }
    </Draggable>
  )
})

export function QuestionsTable() {
  const questions = useQuestionStore(state => state.questionList)
  const moveQuestion = useQuestionStore(state => state.moveQuestion)

  return (
    <DragDropContext
      onDragEnd={({source, destination}) => {
        if (destination && source.index !== destination.index) {
          console.log('DRAGGED', source, destination)
          moveQuestion(source.index, destination.index)
        }
      }}
    >
      <Table sx={{tableLayout: 'fixed'}}>
        <thead>
          <tr>
            <th style={{width: 40}}></th>
            <th style={{width: '5em'}}>Enabled</th>
            <th style={{width: '15%'}}>Topic</th>
            <th>Question</th>
            <th style={{width: '72px'}}></th>
          </tr>
        </thead>

        <Droppable droppableId="dnd-list" direction="vertical">
          {(provided) =>
            <tbody {...provided.droppableProps} ref={provided.innerRef}>
              {questions.map((question, i) =>
                <QuestionRow key={question.id} i={i} question={question} />
              )}

              {provided.placeholder}
            </tbody>
          }
        </Droppable>
      </Table>
    </DragDropContext>
  )
}
