import { useRef, useEffect, useId, useState, useCallback, memo } from 'react'
import 'simple-keyboard/build/css/index.css'
import SimpleKeyboard from 'simple-keyboard/build/index.modern'

import { Box } from '@mantine/core'

type KeyboardOptions = SimpleKeyboard["options"]

type KeyboardProps = {
  theme?: string,
  value?: string,
  onEnter?: () => void,
  onAccept?: () => void,
  onCancel?: () => void,
  style?: React.CSSProperties,
  sx?: any,
  textarea?: HTMLTextAreaElement,
} & KeyboardOptions

export const Keyboard = memo(({
  value, onEnter, onAccept, onCancel,
  style, sx, textarea,
  ...props
}: KeyboardProps) => {
  const targetElemRef = useRef<null | HTMLDivElement>(null)
  const keyboardRef = useRef<null | SimpleKeyboard>(null)

  const [layoutName, setLayoutName] = useState(props.layoutName || 'default');
  const [capsLock, setCapsLock] = useState(false)

  const onKeyPress = useCallback((button: string) => {
    console.log('onKeyPress', button, layoutName, capsLock)
    if (button === '{shift}' || button === '{lock}') {
      const shouldShift = (layoutName === 'default')
      setCapsLock(button === '{lock}' && shouldShift)
      setLayoutName(shouldShift ? 'shift' : 'default')
    }
    else if (button === '{enter}') {
      onEnter?.()
    }
    else if (button === '{accept}') {
      onAccept?.()
    }
    else if (button === '{cancel}') {
      onCancel?.()
    }
    else {
      if (layoutName === 'shift' && !capsLock) {
        setLayoutName('default')
      }
    }

    props.onKeyPress?.(button)
  }, [layoutName, capsLock, onEnter, onAccept, onCancel])

  useEffect(() => {
    const keyboard = keyboardRef.current
    if (!keyboard) return

    if (value != null) keyboard.setInput(value)

    if (keyboard.options.disableCaretPositioning) {
      const len = value?.length || 0
      keyboard.options.disableCaretPositioning = false
      keyboard.setCaretPosition(len, len)
    }

    if (textarea) {
      textarea.selectionStart = keyboard.getCaretPosition()
      textarea.selectionEnd = keyboard.getCaretPositionEnd()
    }
  }, [value, textarea])


  const onInit = useCallback((keyboard?: SimpleKeyboard) => {
    if (!keyboard) return

    if (value != null) {
      keyboard.setInput(value)
    }
    const len = value?.length || 0
    keyboard.setCaretPosition(len, len)
    keyboard.options.disableCaretPositioning = true

  }, [value, textarea])

  useEffect(() => {
    const currentClass = keyboardRef.current?.keyboardDOMClass
    const newClass = targetElemRef.current?.classList[0]

    // console.log('targetElem', targetElemRef.current, 'dom', keyboardRef.current?.keyboardDOM)
    // console.log('class', newClass, currentClass)
    if (newClass && currentClass !== newClass) {
      targetElemRef.current!.innerHTML=''

      const theme = `simple-keyboard ${props.theme || 'hg-theme-default'}`
      keyboardRef.current = new SimpleKeyboard(
        newClass,
        {...props, onKeyPress, theme, onInit}
      )
    }


    const keyboard = keyboardRef.current
    keyboard?.setOptions({...props, onKeyPress, layoutName})

  }, [keyboardRef, targetElemRef, props, layoutName, capsLock])

  useEffect(() => {
    const el = targetElemRef.current
    if (!el) return

    const shift = (layoutName === 'shift')

    el.classList.toggle('shift-active', shift && !capsLock)
    el.classList.toggle('caps-active', shift && capsLock)

  }, [layoutName, capsLock, targetElemRef, keyboardRef])

  return <Box sx={sx} ref={targetElemRef} />
})
