import { useRef, useState, useEffect, useMemo, memo } from 'react'
import { BoxProps, JsonInput } from '@mantine/core'
import { Stack, Box, Flex, Button, Group, Paper, Tabs } from '@mantine/core'
import { Text, Divider } from '@mantine/core'
import { useQuestionStore, checkValidQuestions } from '../model/questions'

import { useTimeout } from '@mantine/hooks';
import { Notification, Transition } from '@mantine/core'


import { LightMode } from '../components/ColorMode'
import { QuestionEditor } from '../components/QuestionEditor'
import { QuestionsTable } from '../components/QuestionsTable'

type Notification = {
  title: string,
  message: string,
}

function RawData() {
  const [questionsJSON, setQuestionsJSON] = useState<string>('')

  const jsonRef = useRef<HTMLTextAreaElement>(null)

  const questions = useQuestionStore(state => state.questions)
  const version = useQuestionStore(state => state.version)

  useEffect(() => {
    const json = JSON.stringify({version, questions}, null, 2)
    setQuestionsJSON(json)
  }, [questions, version])


  // async function saveQuestionsJSON() {
  //   const data = JSON.parse(questionsJSON) as QuestionConfig

  //   useQuestionStore.setState(draft => {
  //     draft.questions = data.questions
  //     draft.allQuestionSets = data.questionSets
  //     draft.questionSets = data.questionSets.filter(q => q.enabled)
  //   })

  //   await saveQuestionConfig()

  //   console.log(data)
  //   notify('Success', 'Questions JSON has been updated')
  // }

  return (
    <Stack h="100%" mih='0' justify="stretch">
    {questionsJSON && <JsonInput
          styles={{
            root: {
              display: 'flex',
              flexDirection: 'column',
            },
            wrapper: {
              flex: '1',
            },
            input: {
              height: '100%',
            },
          }}
          ref={jsonRef}
          sx={{flex: "1 1 auto"}}
          label="Questions JSON"
          validationError="Invalid json"
          value={questionsJSON}
          readOnly
          // onChange={setQuestionsJSON}
          formatOnBlur
      /> }

{/*            <Group position="right" mt="xl">
        <Button disabled={!questionsJSON} onClick={saveQuestionsJSON}>Update</Button>
      </Group>
*/}
    </Stack>
  )

}

function ResponsesTab() {
  const [responseStats, setResponseStats] = useState<{count?: number, latest?: number} | null>(null);
  async function loadResponseStats() {
    const response = await fetch('/api/responses/stats')
    const data = await response.json()
    console.log('loadResponseStats', data)
    setResponseStats(data)
  }
  useEffect(() => { loadResponseStats() }, [])

  return (
    <Stack p="md">
      {responseStats &&
        <Text size="sm">
          {responseStats.count} responses, most recent:
          {responseStats.latest ? new Date(responseStats.latest*1000).toLocaleString() : '(never)'}
        </Text>
      }
      <Group>
        <Button component="a" href="/api/responses/csv">Download responses CSV</Button>
        <Button component="a" href="/api/questions/csv">Download questions CSV</Button>
      </Group>
    </Stack>
  )
}

type QuestionsTabProps = {
  onSaveQuestions: () => void
}

function QuestionsTab({onSaveQuestions}: QuestionsTabProps) {
  const questions = useQuestionStore(state => state.questions)

  const hasChanges = useQuestionStore(state => state.hasChanges)
  const editQuestion = useQuestionStore(state => state.editQuestion)

  const createQuestion = () => {
    editQuestion({question: {}})
  }

  const valid = useMemo(() => checkValidQuestions(questions), [questions])

  return (
    <Stack h="100%" mih='0'>
      <Stack sx={{overflowY: 'scroll'}}>
        <QuestionsTable />
      </Stack>

      <Paper sx={{alignSelf: 'flex-end'}}>
        <Group>
          <Button onClick={createQuestion}>New question</Button>
          <Button disabled={!hasChanges || !valid} onClick={onSaveQuestions}>Save changes</Button>
        </Group>
      </Paper>
    </Stack>
  )
}

function AdminInner() {
  const fetchQuestionConfig = useQuestionStore(state => state.fetchQuestionConfig)
  const saveQuestionConfig = useQuestionStore(state => state.saveQuestionConfig)


  useEffect(() => { fetchQuestionConfig() }, [])

  async function onSaveQuestions() {
    await saveQuestionConfig()

    notify('Success', 'Questions have been updated')
  }

  const editQuestion = useQuestionStore(state => state.editQuestion)

  const [notification, setNotification] = useState<Notification | null>(null);
  const notificationTimeout = useTimeout(() => {
      console.log('callback')
      setNotification(null)
  }, 4000)

  const notify = (title: string, message: string) => {
    notificationTimeout.clear()
    setNotification({title, message})
    notificationTimeout.start()
  }


  return (
  <Paper w="100%" h="100%">
    <Transition mounted={!!notification} transition="fade" duration={600} timingFunction="ease">
      {(styles) =>
        <Notification title={notification?.title} style={{...styles, position: 'absolute', top: '16px', right: '16px'}}>
          {notification?.message}
        </Notification>
      }
    </Transition>

    <QuestionEditor />



    <Stack justify="flex-start" px="xl" py="xl" h="100%">
      <Tabs
        defaultValue="questions"
        sx={{flex: '1 1 auto', minHeight: 0, display: 'flex', flexDirection: 'column'}}
        keepMounted={false}
      >
        <Tabs.List>
          <Tabs.Tab value="responses">Responses</Tabs.Tab>
          <Tabs.Tab value="questions">Questions</Tabs.Tab>
          <Tabs.Tab value="raw">Raw JSON</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="responses" h="100%" mih='0'>
          <ResponsesTab />
        </Tabs.Panel>

        <Tabs.Panel value="questions" h="100%" mih='0'>
          <QuestionsTab onSaveQuestions={onSaveQuestions} />
        </Tabs.Panel>


        <Tabs.Panel value="raw" h="100%" mih='0'>
          <RawData />
        </Tabs.Panel>
      </Tabs>
      </Stack>
    </Paper>
  )
}

function Admin() {
  return (
    <LightMode>
      <AdminInner />
    </LightMode>
  )
}

export default Admin
