import { MantineProvider } from '@mantine/core'

import { forwardRef } from 'react'

type AnyComponent = keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
// const bindProps = <T extends AnyComponent>(Component: T, boundProps: Omit<React.ComponentProps<T>, "children">) =>
//   forwardRef(({children, ...props}: React.ComponentProps<T>, ref) =>
//     <Component {...boundProps} {...props}>{children}</Component>)

// const LightMode = bindProps(MantineProvider, {inherit: true, theme:{primaryColor: 'gray'}})

// const DarkMode = bindProps(MantineProvider, {inherit: true, theme:{primaryColor: 'dark'}})

export const LightMode = forwardRef(({children, ...props}: React.ComponentProps<typeof MantineProvider>, ref) =>
  <MantineProvider inherit theme={{primaryColor: 'brand', colorScheme: 'light'}} {...props}>{children}</MantineProvider>)

export const DarkMode = forwardRef(({children, ...props}: React.ComponentProps<typeof MantineProvider>, ref) =>
  <MantineProvider inherit theme={{colorScheme: 'dark'}} {...props}>{children}</MantineProvider>)
