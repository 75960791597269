import { useCallback, useEffect, useMemo } from 'react'
import { MantineProvider } from '@mantine/core'
import { useGradient, Gradient } from '../model/gradients'

const nextFrame = () => new Promise(requestAnimationFrame)


type Point = {x: number, y: number}


import { resolvePosition, useBlinds, useDummyBlinds } from '../model/blinds'

import type { Store, Blind } from '../model/blinds'

const mix = (a: number, b: number, t: number) => a * (1-t) + b * t

const sampleBlinds = (blinds: Blind[], x: number, t: number, newt: number) => {
  const i = Math.floor(x * blinds.length)
  const it = x * blinds.length - i

  const p1 = resolvePosition(blinds[i], t, newt)
  if (it === 0) return p1
  const p2 = resolvePosition(blinds[i+1], t, newt)

  return mix(p1, p2, it)
}


const N_BLACK = 3;
const N_WHITE = 23;

const SOFTOFFSET = 0.14;
const SOFTSCALE = 0.25;

async function drawLoop(canvas: HTMLCanvasElement, data: {color: Gradient}, signal: AbortSignal) {
  const ctx = canvas.getContext('2d')
  if (!ctx) throw new Error('no canvas context')

  let W = canvas.width = canvas.offsetWidth
  let H = canvas.height = canvas.offsetHeight

  const observer = new ResizeObserver(() => {
    W = canvas.width = canvas.offsetWidth
    H = canvas.height = canvas.offsetHeight
  })
  observer.observe(canvas)


  const Pt = (x: number, y: number) => ({x: x * W, y: y * H})
  const line = (p1: Point, p2: Point) => {
    ctx.moveTo(p1.x, p1.y)
    ctx.lineTo(p2.x, p2.y)
  }

  while (!signal.aborted) {
    const newt_ms = await nextFrame()
    const newt = Date.now() / 1000
    const {t, white, black, faux} = useBlinds.getState()

    ctx.clearRect(0, 0, W, H)

    const gradient = ctx.createLinearGradient(0, H/2, W, H/2)

    gradient.addColorStop(0, data.color.from)
    gradient.addColorStop(1, data.color.to)

    ctx.strokeStyle = gradient
    ctx.lineWidth = 6
    ctx.lineCap = 'round'


    const wmargin = 1 / (N_WHITE+1)
    const wrange = 1.0 - (wmargin * 2)

    ctx.beginPath()
    for (let i = 0; i < N_WHITE; i++) {
      const x = i/(N_WHITE-1) * wrange + wmargin

      const y = sampleBlinds(white, i/N_WHITE, t, newt) * SOFTSCALE + SOFTOFFSET

      line(Pt(x, 0), Pt(x, y))
    }

    ctx.stroke()


    ctx.strokeStyle = data.color
    ctx.lineWidth = 6
    ctx.lineCap = 'round'

    ctx.beginPath()

    const bmargin = 1/(N_BLACK+1)
    const brange = 1.0 - (bmargin * 2)

    // for (const [i, blind] of black.entries()) {
    //   const x = i / (N_BLACK-1) * brange + bmargin

    //   const y = resolvePosition(blind, t, newt) * SOFTSCALE + SOFTOFFSET


    //   line(Pt(x, 0), Pt(x, y))
    // }

    for (const [i, blind] of faux.entries()) {
      const x = i / (N_BLACK-1) * brange + bmargin

      const y = resolvePosition(blind, t, newt)


      line(Pt(x, 0), Pt(x, y))
    }

    ctx.stroke()
  }

  console.log('done')
  observer.disconnect()
}


type BlindsProps = {
  color: string
}

function Blinds({color}: BlindsProps) {

  let controller: AbortController | null = null

  const gradient = useGradient()

  useBlinds()
  // useDummyBlinds()

  const data = useMemo(() => ({color: gradient}), [])

  useEffect(() => {
    data.color = gradient
  }, [gradient])

  const ref = useCallback((canvas: HTMLCanvasElement) => {
    console.log('new blinds')
    if (controller) controller.abort()
    controller = null

    if (canvas) {
      controller = new AbortController()
      drawLoop(canvas, data, controller.signal)
    }
  }, [])

  return <canvas
    ref={ref}
    style={{
      top: 0,
      left: 0,
      width: '100%', height: '100%',
      position: 'fixed',
      pointerEvents: 'none'
    }}
    >
  </canvas>
}

export default Blinds
