import create from 'zustand'

export type Screen = 'consent' | 'home' | 'questions';

export interface ScreenStore {
  screen: Screen
  start: () => void
  decline: () => void
  accept: () => void
  done: () => void
}

export const useScreenStore = create<ScreenStore>()((set) => ({
  screen: 'home',
  start: () => set(() => ({ screen: 'consent' })),
  decline: () => set(() => ({ screen: 'home' })),
  accept: () => set(() => ({ screen: 'questions' })),
  done: () => set(() => ({ screen: 'home' })),
}))
