import { useCallback, useState, useEffect } from 'react'
import { Box, Stack, Flex, Button, Group, ColorPicker, Modal } from '@mantine/core'
import { Text, Title, Tabs } from '@mantine/core'

type BoundColorPickerProps = {
  label?: string,
  varName: string
}

const swatches = ['#000000', '#FFFFFF', '#FFED4F', '#FF494E', '#E432F5', '#141CFD']

function BoundColorPicker({varName, label}: BoundColorPickerProps) {
  const style = getComputedStyle(document.body)
  const [color, setColor] = useState(style.getPropertyValue(varName))

  useEffect(() => {
    document.body.style.setProperty(varName, color)
    localStorage[varName] = color
  }, [color])

  return (
    <Box ml="lg">
      <Text align="center" size="md">{label || ''} {color.toUpperCase()}</Text>
      <ColorPicker
        format="hex"
        swatches={swatches}
        swatchesPerRow={6}
        value={color}
        onChange={setColor}
        size="lg"
      />
    </Box>
  )
}

export type ColorPanelProps = {
  onClose?: () => void,
}

function ColorPanel({onClose}: ColorPanelProps) {

  const onClose2 = onClose || (() => {})
  return (
    <Modal
      opened
      size="80%"
      sx={{
        marginTop: '5%',
      }}
      onClose={onClose2}
      radius="xl"
    >
    <Tabs defaultValue="background">
      <Tabs.List grow>
        <Tabs.Tab value="background"><Text size="lg">Background</Text></Tabs.Tab>
        <Tabs.Tab value="foreground"><Text size="lg">Blinds</Text></Tabs.Tab>
        <Tabs.Tab value="about"><Text size="lg">About</Text></Tabs.Tab>
      </Tabs.List>


      <Tabs.Panel value="background" pt="48px">
        <Stack align="left" justify="center" px="40px" pb="60px">
          <Group align="stretch">
            <BoundColorPicker varName="--bg-gradient-start" />
            <Box
              ml="xl"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '26px',
                // height: '50%',
                flex: '1 1 auto',
                background: 'linear-gradient(90deg, var(--bg-gradient-start), var(--bg-gradient-end))',
              }}
            >
            <Text
              align="center"
              size="lg"
              color="var(--splash-text-color)"
            >
              WHAT ARE YOUR<br />
              ETHICAL BOUNDARIES
            </Text>
            </Box>
            <BoundColorPicker varName="--bg-gradient-end" />
          </Group>

          <Flex align="stretch" justify="center" w="100%">
            <BoundColorPicker label="Text:" varName="--splash-text-color" />
          </Flex>

        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="foreground" pt="48px">
        <Stack align="left" justify="center" px="40px" pb="60px">
          <Group align="stretch">
            <BoundColorPicker varName="--fg-gradient-start" />
            <Box
              ml="xl"
              sx={{
                display: 'flex',
                alignItems: 'top',
                justifyContent: 'center',
                marginTop: '26px',
                flex: '1 1 auto',
                background: 'black',
                position: 'relative',
              }}
            >
              <Text
                align="center"
                size={80}
                color="white"
                sx={{
                  lineHeight: 0.85
                }}
              >
                | | | | | | | | | | | | | | | | | | | | | | |
              </Text>
              <Box
              sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                background: 'linear-gradient(90deg, var(--fg-gradient-start), var(--fg-gradient-end))',
                mixBlendMode: 'multiply'
              }}
              />
            </Box>
            <BoundColorPicker varName="--fg-gradient-end" />
          </Group>
        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="about" pt="48px">
        <Stack align="left" justify="center" px="40px" pb="60px">
          <Group align="stretch">
            <BoundColorPicker varName="--about-gradient-start" />
            <Box
              ml="xl"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '26px',
                flex: '1 1 auto',
                background: 'linear-gradient(90deg, var(--about-gradient-start), var(--about-gradient-end))',
              }}
            >
            <Text
              align="center"
              size={10}
              color="var(--about-text-color)"
              sx={{
                width: '100%',
              }}
            >
              Look up. The blinds move with you and reflect your<br />
              choices. As you answer ethical questions, watch<br />
              how the canopy above responds to your input.
            </Text>
            </Box>
            <BoundColorPicker varName="--about-gradient-end" />
          </Group>

          <Flex align="stretch" justify="center" w="100%">
            <BoundColorPicker label="Text:" varName="--about-text-color" />
          </Flex>

        </Stack>
      </Tabs.Panel>
    </Tabs>

    </Modal>
  )
}

export default ColorPanel
