import axios from 'axios'

const API_PATH = '/api/'

export const api = axios.create({ baseURL: API_PATH, timeout: 2000 })

export const blindsApi = axios.create({ baseURL: API_PATH + 'blinds/', timeout: 2000 })

const checkBlinds = async () => {
  const has_blinds = (await api.get(`has_blinds`)).data
  console.log('has blinds?', has_blinds)

  if (!has_blinds) {
    blindsApi.defaults.adapter = async (config) => {
      return {
         data: {},
         status: 200,
         statusText: 'OK',
         headers: {},
         config: {},
         request: {}
       }
    }
  }
}


try {
  checkBlinds()
}
catch (e) { }
