import { useCallback, useState, useEffect, useMemo, useRef, forwardRef } from 'react'

import { Flex, Button, Title, Radio, Modal, RingProgress, Stack, useMantineTheme, Text, Box, keyframes } from '@mantine/core'
import type { FlexProps } from '@mantine/core'
import { motion } from 'framer-motion'

import { useIdle, useInterval } from '@mantine/hooks'

import { useBlinds, WRANGE } from '../model/blinds'
import { useGradient } from '../model/gradients'
import { useScreenStore } from '../model/screen'
import { useQuestionStore, Question, sampleAnswers } from '../model/questions'

import Blinds from '../components/Blinds'


const IDLE_TIMEOUT_1 = 30*1000
const IDLE_TIMEOUT_2 = 30*1000
const QUESTION_COOLDOWN = 10*1000

const FADE = `600ms ease-out`

const pulseAnimation = keyframes({
  '0%': { opacity: 1.0 },
  '20%': { opacity: 0.1 },
  '50%': { opacity: 1.0 },
  '100%': { opacity: 1.0 },
})

const SIDES = ['left', 'middle', 'right'] as const
type Side = typeof SIDES[number]

const halfmirror2 = (a: number[]): number[] => {
  const a2 = []
  const aa = a
  for (const [i, x] of aa.entries()) {
    if (i % 2 === 0) {
      a2.push(x)
    }
    else {
      a2.unshift(x)
    }
  }
  return a2
}

const getSamplePositions = async (id: string, side: Side): Promise<number[] | undefined> => {
  const samples = await sampleAnswers(id, WRANGE-2)
  console.log(id, 'samples', samples, side)
  if (!samples) return

  const positions = samples.map(v => v/4).sort((a, b) => a - b)

  if (side === 'left' || side === 'right') {
    return positions
  }
  else {
    return positions.reverse()
  }
}

const STEPS = {
  BEGIN: 0,
  LIKERT: 1,
  FINISH: 2,
}

const N_STEPS = Object.keys(STEPS).length

type QuestionSeriesProps = {
  pos: number,
  title: string,
  cooldown?: number | null,
  question: Question,
  onStart?: (pos: number) => void,
  onDone?: (pos: number) => void,
  onCooldown?: (pos: number) => void,
  disabled?: boolean
}

function QuestionSeries({pos, title, question, onStart, onDone, disabled, cooldown, onCooldown}: QuestionSeriesProps) {
  const [step, setStep] = useState(0)
  const [answered, setAnswered] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  const positionRef = Array.from({length: N_STEPS}, () => useRef<HTMLDivElement>(null))

  const [blindAnswer, setBlindAnswer] = useState<[number, string, number]>([0, '', 0])
  const answer = useQuestionStore((state) => state.answer)

  const finish = useCallback(() => {
    setStep(STEPS.BEGIN)
    setAnswered(true)
    const [pos, sv, v] = blindAnswer
    console.log('pos', pos, 'value', v)

    getSamplePositions(question.id, SIDES[pos])
      .then(positions => {
        triggerBlinds(pos, v, positions)
      })
    moveFaux(pos, 0)
    onDone?.(pos)
  }, [blindAnswer, question, pos])

  const nextStep = useCallback(() => {
    if (step === STEPS.BEGIN) {
      onStart?.(pos)
    }
    if (step < STEPS.FINISH) {
      setStep(step + 1)
    }
  }, [step, pos])

  useEffect(() => {
    const ref = positionRef[step]
    if (!ref.current) return

    const el = ref.current.querySelector('.mantine-InputWrapper-label, .mantine-Button-root')
    if (!el) return

    moveFaux(pos, el.getBoundingClientRect().bottom / window.innerHeight)
  }, [step, ...positionRef])

  const triggerBlinds = useBlinds(state => state.answer)
  const moveFaux = useBlinds(state => state.moveFaux)

  const onBlindAnswer = useCallback((v: string) => {
    const v2 = +v/4
    console.log('setBlindAnswer', 'pos', pos, 'v', v, 'value', v2)
    setBlindAnswer([pos, v, v2])
    answer(question.id, +v)
    nextStep()
  }, [pos, question, nextStep])

  return <Flex
    ref={ref}
    direction="column"
    w="22%"
    pos="absolute"
    left={25+(pos)*25 + '%'}
    top="40%"
    align="start"
    gap="lg"
    ml='22.5px'
  >
    <Box pos="relative">
      <Title size="h3" sx={{display: "flex", alignItems: "end"}} color={disabled ? 'dimmed' : ''}>
        {title}
      </Title>

      <Box
        ref={positionRef[STEPS.BEGIN]}
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          visibility: step === STEPS.BEGIN ? 'visible' : 'hidden',
          opacity: step === STEPS.BEGIN ? 1 : 0,
          transition: `opacity ${FADE}`,
        }}
      >
        <Button
          size="md" radius="xl"

          disabled={disabled || answered}
          styles={(theme) => ({
            inner: (!disabled && !answered) ? {
              animation: `${pulseAnimation} 1.6s ease-out infinite`,
            } : {},
            root: {
              zIndex: 1,
              '&:disabled': {
                borderColor: disabled ? theme.colors.dark[2] : theme.colors.dark[0],
                borderWidth: '2px',
                color: disabled ? theme.colors.dark[2] : theme.colors.dark[0],
                backgroundColor: 'transparent',
              },
            },
          })}
          onClick={nextStep}
        >
          { answered ? 'Done' : 'Start' }
        </Button>

        { cooldown &&
          <Cooldown
            startTime={cooldown}
            duration={QUESTION_COOLDOWN}
            onDone={() => onCooldown?.(pos)}
          />
        }
      </Box>
    </Box>

    <Box
      ref={positionRef[STEPS.LIKERT]}
      sx={{
        position: 'relative',
        marginTop: '0.5em',
      }}
    >

      <Radio.Group
        label={question.question}
        name={`radio-${pos}-2`}
        key={`radio-${pos}-2-expand`}
        orientation="horizontal"
        spacing={1}
        size="sm"
        styles={{
          root: {
            position: 'absolute',
            flexWrap: 'nowrap',
          },
        }}
        sx={{
          visibility: step === STEPS.LIKERT ? 'visible' : 'hidden',
          opacity: step === STEPS.LIKERT ? 1 : 0,
          pointerEvents: step === STEPS.LIKERT ? undefined : 'none',
          transition: `opacity ${FADE}`,
          '.mantine-Group-root::before': {
            position: 'absolute',
            display: 'block',
            content: '""',
            borderTop: '1px dashed white',
            width: '80%',
            left: '10%',
            marginTop: '8px',
          },
          '.mantine-Group-root': {
            flexWrap: 'nowrap',
            alignItems: 'flex-start',
            paddingTop: '16px',
          },
          '.mantine-RadioGroup-label': { fontWeight: 400 },
        }}
        onChange={onBlindAnswer}
      >
        {question.options?.map((label, i) =>
          <Radio
            key={`radio-${pos}-2-${i}-expand`}
            size="sm"
            value={''+i}
            label={label}
            disabled={step !== STEPS.LIKERT}
            styles={theme => ({
              root: {
                maxWidth: '20%',
                flex: '1 1 auto',
              },
              body: {
                flexDirection: 'column',
                alignItems: 'center',
                gap: '0.5em',
              },
              inner: {
                alignSelf: 'center',
              },
              label: {
                textAlign: 'center',
                lineHeight: '1.3',
                fontSize: theme.fontSizes.xs,
                padding: '32px 6px',
                margin: '-32px 0',
              },
            })}
          />
        )}
      </Radio.Group>

      <Radio.Group
        label={question.question}
        name={`radio-${pos}-2`}
        key={`radio-${pos}-2-collapse`}
        orientation="vertical"
        spacing={1}
        size="sm"
        sx={{
          visibility: step > STEPS.LIKERT ? 'visible' : 'hidden',
          opacity: step > STEPS.LIKERT ? 1 : 0,
          pointerEvents: 'none',
          transition: `opacity ${FADE}`,
          '.mantine-RadioGroup-label': { fontWeight: 400 },
          '& .mantine-Stack-root': {
            minHeight: '46px',
          },
        }}
        value={blindAnswer[1] as string}
        wrapperProps={{
          styles: {
            label: {
              minHeight: '2em',
            }
          },
        }}
      >
        {question.options?.map((label, i) =>
          (''+i === blindAnswer[1]) && <Radio
            key={`radio-${pos}-2-${i}-collapse`}
            size="sm"
            value={''+i}
            label={label}
            disabled={step !== STEPS.LIKERT}
            mx="-32px"
            styles={{
              label: {
                minHeight: '2em'
              },
            }}
          />
        )}
      </Radio.Group>
    </Box>

    <Box ref={positionRef[STEPS.FINISH]}>
      <Button
        size="md" radius="xl"
        disabled={step < STEPS.FINISH}
        styles={(theme) => ({
          root: {
            // marginTop: '1em',
            visibility: step >= STEPS.FINISH ? 'visible' : 'hidden',
            opacity: step >= STEPS.FINISH ? 1 : 0,
            transition: `opacity ${FADE}`,

            '&:disabled': {
              borderColor: theme.colors.dark[2],
              borderWidth: '2px',
              color: theme.colors.dark[2],
              backgroundColor: 'transparent',
            },
          },
        })}
        onClick={finish}
      >
        Submit
      </Button>
    </Box>

  </Flex>
}


const useReset = () => {
  const resetBlinds = useBlinds((state) => state.reset)
  const resetQuestions = useQuestionStore((state) => state.reset)

  return useCallback(() => {
    resetBlinds()
    resetQuestions()
  }, [])
}

type CooldownProps = {
  startTime: number,
  duration: number,
  onDone?: () => void,
}
function Cooldown({startTime, duration, onDone}: CooldownProps) {
  const theme = useMantineTheme()
  let [value, setValue] = useState(100)
  let [remain, setRemain] = useState(Math.ceil(duration / 1000))

  useEffect(() => {
    let running = true
    let doneCalled = false

    function raf() {
      if (!running) return

      const dt = Math.min((Date.now() - startTime) / duration, 1.0)
      setRemain(Math.ceil((1.0 - dt) * duration / 1000))
      setValue(dt * 100)

      requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)

    const timeout = setTimeout(() => {
      running = false
      doneCalled = true
      onDone?.()
    }, duration - (Date.now() - startTime))
    // console.log('timeout time', duration - (Date.now() - startTime))

    return (() => {
      // console.log(`${startTime} + ${duration} <= ${Date.now()}`, startTime + duration <= Date.now())
      if (!doneCalled && startTime + duration <= Date.now()) {
        onDone?.()
      }

      running = false
      clearTimeout(timeout)
    })
  }, [startTime, duration, onDone])

  return (
      <RingProgress
        label={
          <Text size="md" align="center">
            {remain || ''}
          </Text>
        }
        size={42}
        thickness={2}
        sections={[{value: value, color: theme.colors.dark[7]}, {value: 100-value, color: theme.colors.dark[0]}]}
        mx="xs"
      />
  )
}

function TimeoutModal() {
  const theme = useMantineTheme()
  let [value, setValue] = useState(100)
  let [remain, setRemain] = useState(Math.ceil(IDLE_TIMEOUT_2 / 1000))

  useEffect(() => {
    const startTime = Date.now()

    const timeout = setInterval(() => {
      const dt = Math.min((Date.now() - startTime) / IDLE_TIMEOUT_2, 1.0)

      setRemain(Math.ceil((1.0 - dt) * IDLE_TIMEOUT_2 / 1000))

      setValue(dt * 100)
    }, 60/1000)

    return (() => clearTimeout(timeout))
  }, [])

  return (
    <Modal
      opened
      centered
      withCloseButton={false}
      size="auto"
      onClose={()=>{}}
      overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]}
      radius="xl"
    >
      <Stack align="center" px="80px" pt="40px" pb="60px">
      <Title size="h2" sx={{textTransform: 'none'}}>Are you still there?</Title>

      <Text size={170}>{remain}</Text>
      <Button size="xl" radius="xl" fullWidth>Continue</Button>
      </Stack>

    </Modal>
  )
}

type QuestionsProps = {
  onDone?: () => void,
} & FlexProps



export const Questions = motion(forwardRef<HTMLDivElement, QuestionsProps>(
  ({onDone, ...props}: QuestionsProps, ref) => {
    const done = useScreenStore((state) => state.done)

    const idle1 = useIdle(IDLE_TIMEOUT_1, { initialState: false })
    const idle2 = useIdle(IDLE_TIMEOUT_1 + IDLE_TIMEOUT_2, { initialState: false })

    const reset = useReset()

    const questionList = useQuestionStore((state) => state.enabledQuestionList)
    const chosenQuestions = useQuestionStore((state) => state.chosenQuestions)
    const replaceSequentialQuestion = useQuestionStore((state) => state.replaceSequentialQuestion)
    const gradient = useGradient()

    if (!chosenQuestions) return null

    const [currentPos, setCurrentPos] = useState<number | null>(null)

    const questions = useMemo(() =>
        chosenQuestions?.map(x => questionList[x]),
    [questionList, chosenQuestions])

    const [cooldowns, setCooldowns] = useState<(number | null)[]>([null, null, null])
    const finish = useCallback(async () => {
      done()
      reset()
      onDone?.()
    }, [onDone])


    const questionSetStart = useCallback((pos: number) => {
      setCurrentPos(pos)
    }, [])

    const questionSetDone = useCallback((pos: number) => {
      const cool = cooldowns.slice()
      cool[pos] = Date.now()
      setCooldowns(cool)

      setCurrentPos(null)
    }, [cooldowns])

    const onCooldown = useCallback((pos: number) => {
      const cool = cooldowns.slice()
      cool[pos] = null
      setCooldowns(cool)

      setTimeout(() => {
        replaceSequentialQuestion(pos)
      }, 0)
    }, [cooldowns])


    useEffect(() => {
      if (idle2) finish()
    }, [idle2])

    const [aboutOpened, setAboutOpened] = useState(false)

    return (
      <Flex
        h="100%"
        direction="column"
        align="center"
        justify="center"
        py="10"
        pos="relative"
        ref={ref}
        sx={{background: 'black'}}
        {...props}
      >
        <Blinds color="gradient" />

        {
          questions.map((question, i) =>
            <QuestionSeries
              title={question.subject}
              key={question.id}
              pos={i}
              question={question}
              onStart={questionSetStart}
              onDone={questionSetDone}
              cooldown={cooldowns[i]}
              onCooldown={onCooldown}
              disabled={currentPos != null && currentPos !== i}
            />
          )
        }

        { idle1 && <TimeoutModal /> }

        <Modal
          opened={aboutOpened}
          fullScreen
          styles={{
            modal: {
              background: `linear-gradient(90deg, ${gradient.from}, ${gradient.to})`,
            },
            close: {
              '& svg': {
                width: '100%',
                height: '100%',
                color: 'black',
              },
              width: '64px',
              height: '64px',
            },
            body: {
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              display: 'flex',
              align: 'center',
              justify: 'center',
              pointerEvents: 'none',
            },
          }}
          onClose={()=>{setAboutOpened(false)}}
        >
          <Stack align="center" justify="center" px="31%" h="100%">
          <Text
            align="center"
            size={34}
            color="black"
          >
            Look up. The blinds move with you and reflect your choices.
            As you answer ethical questions, watch how the canopy above responds to your input.
          </Text>

          <Text
            size={34}
            sx={{
              position: 'absolute',
              bottom: '72px',
            }}
            color="black"
          >
            Interactive artwork by Junior Major
          </Text>
          </Stack>

        </Modal>

        <Flex justify="start" gap="lg" align="end" sx={{flex: 1}} w="100%" pb={36} px={32}>
          <Button size="lg" radius="xl" variant="white" onClick={() => {setAboutOpened(true)}}>About</Button>

          <Button size="lg" radius="xl" variant="white" onClick={finish}>Restart</Button>
        </Flex>
      </Flex>
    )
  })
)
