import { ChangeEvent, useCallback } from 'react'
import { Stack, Button } from '@mantine/core'
import { TextInput, Textarea, Divider, Modal, List, SegmentedControl } from '@mantine/core'


import { useQuestionStore, isValidQuestion, N_OPTIONS } from '../model/questions'

type OptionEditorProps = {
  options?: string[],
}

function OptionEditor({options}: OptionEditorProps) {
  const updateOption = useQuestionStore(state => state.updateEditQuestionOption)

  const displayOptions = Array.from({length: N_OPTIONS}, (_, i) => options?.[i] || '')

  return (
    <List
      type="ordered"
      pl={32}
      spacing={4}
      styles={{
        item: {
          listStylePosition: 'outside',
        },
        itemWrapper: {
          width: '100%',
        },
      }}
    >
      { displayOptions.map((opt, i) =>
        <List.Item key={''+i} pl="xs">
          <TextInput value={opt} sx={{flex: '1 0 auto'}} onChange={(ev) => updateOption(i, ev.target.value)}></TextInput>
        </List.Item>
      )}
    </List>
  )
}

const noEQS = {question: null, originalId: null}

export function QuestionEditor() {
  const {question: q, originalId} = useQuestionStore(state => state.editQuestionState) || noEQS
  const editQuestion = useQuestionStore(state => state.editQuestion)
  const update = useQuestionStore(state => state.updateEditQuestion)
  const commit = useQuestionStore(state => state.commitEditQuestion)
  const unsavedQuestions = useQuestionStore(state => state.unsavedQuestions)


  const isNew = originalId == null

  const isBound = !!location

  const isSaved = !isNew && !unsavedQuestions.has(originalId)

  const onClose = useCallback(() => {
    console.log('done!', location)
    editQuestion(null)
  }, [])

  const valid = q && isValidQuestion(q)

  const onTextChange = useCallback((ev: ChangeEvent<HTMLTextAreaElement>) => {
    update({question: ev.target.value.replace(/\n/g,'')})
  }, [])

  const onIDChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    update({id: ev.target.value})
  }, [])

  const onSubjectChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    update({subject: ev.target.value})
  }, [])

  return (
    q &&
      <Modal
        centered
        withCloseButton={true}
        closeOnEscape={false}
        title={"Editing " + (originalId || 'New Question')}
        size={480}
        opened={true}
        onClose={onClose}
      >
        <Stack spacing="sm">
          <TextInput label="ID" disabled={isSaved} value={q?.id || ''} onChange={onIDChange}></TextInput>
          <TextInput label="Topic"  styles={{input: {backgroundColor: 'white'}}} value={q?.subject || ''} onChange={onSubjectChange}></TextInput>
          <Textarea label="Question" autosize styles={{input: {backgroundColor: 'white'}}} value={q?.question || ''} onChange={onTextChange}></Textarea>

          <Divider label="Options" labelProps={{size: "sm"}} />
          <OptionEditor options={q?.options} />

          <Button mt="lg" size="sm" disabled={!valid} onClick={commit}>{isNew ? 'Create' : 'Save'}</Button>
        </Stack>
      </Modal>
  )
}

