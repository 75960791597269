import create from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { useEffect } from 'react'

import { blindsApi } from './api'

export type Blind = {
  position: number,
  target: number,
}

export const N_WHITE = 28
export const N_BLACK = 3
export const WRANGE = Math.ceil(N_WHITE/N_BLACK)

const REAL_HEIGHT = 3000

// units per second
// const REAL_SPEED = 60
const REAL_SPEED = 300

export const SPEED = REAL_SPEED/REAL_HEIGHT

const clamp = (v: number, vmin: number, vmax: number) => Math.max(vmin, Math.min(vmax, v))

const mix = (a: number, b: number, t: number) => a * (1-t) + b * t

const lerpPositions = (positions: number[], x: number) => {
  const n = positions.length - 1
  const i = Math.floor(x * n)
  const it = x * n - i

  const y1 = positions[i]
  if (it === 0) return y1
  const y2 = positions[i+1]

  return mix(y1, y2, it)
}

export const resolvePosition = ({position, target}: Blind, t: number, newt: number) => {
  const dt = newt - t
  const dist = SPEED * dt

  const diff = target - position
  const newPosition = position + clamp(diff, -dist, dist)

  return newPosition
}

export const resolveState = (state: State, newt: number) => {
  const {t} = state
  for (const blind of state.white) {
    blind.position = resolvePosition(blind, t, newt)
  }
  for (const blind of state.black) {
    blind.position = resolvePosition(blind, t, newt)
  }
  for (const blind of state.faux) {
    blind.position = resolvePosition(blind, t, newt)
  }

  state.t = newt
}

export type State = {
  t: number,
  scene: string,
  mode: string,
  white: Blind[],
  black: Blind[],
  faux: Blind[],
}

export type Actions = {
  update: (newState: State) => void,
  prepare: () => void,
  answer: (bi: number, n: number, positions?: number[]) => void,
  moveFaux: (bi: number, n: number) => void,
  reset: () => void,

  setSceneMode: (sceneMode: {scene: string, mode: string}) => Promise<void>,
  setScene: (scene: string) => Promise<void>,
  setMode: (mode: string) => Promise<void>,
  setPosition: (color: string, i: number, y: number) => Promise<void>
  setPositions: (color: string, positions: number[]) => Promise<void>
}

export type Store = State & Actions

const mapn = <T,>(length: number, f: (i: number) => T) => Array.from({length}, (_, i) => f(i))


const mkBlind = (position: number, target: number = position) => ({position, target})

const wforb = (bi: number) => {
  const wi = (bi + 0.5) / N_BLACK * (N_WHITE-1)
  const first = Math.ceil(wi - WRANGE/2)
  const last = Math.floor(wi + WRANGE/2)

  return mapn(WRANGE, i => first + i)
}

const S = 2
const scale = (x: number) => (S**(x*x) - 1) / (S - 1)

const MAXY = 0.7
const MINY = 0.3

const scaleY = (y: number) => clamp(y * (MAXY-MINY) + MINY, 0, 1)

const unscaleY = (y: number) => clamp((y - MINY) / (MAXY-MINY), 0, 1)

// const scaleFaux = (y: number) => y / 0.75

export const useBlinds = create(immer<Store>((set, get) => ({
  t: 0,
  scene: 'none',
  mode: 'manual',
  white: mapn(N_WHITE, () => mkBlind(scaleY(Math.random()))),
  black: mapn(N_BLACK, () => mkBlind(scaleY(0))),
  faux: mapn(N_BLACK, () => mkBlind(0)),


  async setPositions(color: string, rawPositions: number[]) {
    const positions = rawPositions.map(scaleY)
    await blindsApi.post(`${color}/positions`, {positions: positions.map(p => Math.floor(p * 255))})

    set(draft => {
      const draftPositions = draft[color as 'white' | 'black']
      for (const [i, pos] of positions.entries()) {
        draftPositions[i].target = pos
      }
    })
  },

  async setPosition(color: string, i: number, y: number) {
    const pos = scaleY(y)
    await blindsApi.post(`${color}/${i}/position`, {position: Math.floor(pos * 255)})
    set(draft => {
      draft[color as 'white' | 'black'][i].target = pos
    })
  },

  async setSceneMode({scene, mode}: {scene: string, mode: string}) {
    await blindsApi.post(`scene`, {scene, mode})
    set(draft => {
      draft.scene = scene
      draft.mode = mode
    })
  },


  async setScene(scene: string) {
    await blindsApi.post(`scene`, {scene})
    set(draft => {
      draft.scene = scene
    })
  },

  async setMode(mode: string) {
    await blindsApi.post(`scene`, {mode})
    set(draft => {
      draft.mode = mode
    })
  },

  prepare() {
    set(draft => {
      // draft.scene = 'manual'
      get().setSceneMode({scene: 'none', mode: 'manual'})
      resolveState(draft, Date.now() / 1000)

      get().setPositions('black', mapn(N_BLACK, () => 0))
      get().setPositions('white', mapn(N_WHITE, () => 0))
    })
  },

  moveFaux(bi: number, n: number) {
    set(draft => {
      resolveState(draft, Date.now() / 1000)
      draft.faux[bi].target = n
    })
  },

  answer(bi: number, n: number, positions?: number[]) {
    set(draft => {
      get().setSceneMode({scene: 'none', mode: 'manual'})
      resolveState(draft, Date.now() / 1000)
      const {white, black} = draft

      n = n * 0.8 + 0.2 // A little bump so we always get some movement
      get().setPosition('black', bi, n)

      const wis = wforb(bi)
      for (const [i, wi] of wis.entries()) {
        let y
        if (positions) {
          y = lerpPositions(positions, i / WRANGE)
        }
        else {
          const dist = Math.ceil(Math.abs(i*2 + 1 - WRANGE))/WRANGE
          y = n * (1 - scale(dist))
        }

        get().setPosition('white', wi, y)
      }

      // Two of the white blinds are exactly in the middle of their respective black blidns
      // So we split the difference
      for (let i = 1; i < N_BLACK; i++) {
        const wi2 = Math.floor(i/N_BLACK * (N_WHITE-1))

        if (!wis.includes(wi2)) continue

        const v1 = white[wi2-1].target
        const v2 = white[wi2+1].target
        console.log('blind-', wi2-1, v1, unscaleY(v1))
        console.log('blind+', wi2+1, v2, unscaleY(v2))
        const v = unscaleY((v1 + v2) / 2)
        console.log('blind', wi2, scaleY(v), v)

        get().setPosition('white', wi2, v)
      }
    })
  },

  update(newState: State) {
    return newState
  },

  reset() {
    set(draft => {
      get().setMode('auto')

      resolveState(draft, Date.now() / 1000)
      get().setPositions('black', mapn(N_BLACK, () => 0))

      for (const blind of draft.faux) blind.target = 0
    })
  }
})))

export async function fetchPositions(color: 'black' | 'white') {
  const {positions} = (await blindsApi(`${color}/positions`)).data

  if (!positions) return

  useBlinds.setState(draft => {
    resolveState(draft, Date.now() / 1000)

    const draftPositions = draft[color]
    for (const [i, pos] of positions.entries()) {
      draftPositions[i].target = pos / 255
    }
  })
}

export const useWatchPositions = (color: 'black' | 'white', t: number = 2000) => {
  let timer: NodeJS.Timer | null = null

  useEffect(() => {
    timer = setInterval(() => fetchPositions(color), t)

    return () => {
      if (timer) clearTimeout(timer)
    }
  })
}

export async function fetchScene() {
  const {scene, mode} = (await blindsApi(`scene`)).data

  if (!screenX) return
  if (!scene || !mode) return

  useBlinds.setState(draft => {
    draft.scene = scene
    draft.mode = mode
  })
}

export const useWatchScene = (t: number = 2000) => {
  let timer: NodeJS.Timer | null = null

  useEffect(() => {
    fetchScene()
    timer = setInterval(fetchScene, t)

    return () => {
      if (timer) clearTimeout(timer)
    }
  })
}

export const useDummyBlinds = () => {
  const store = useBlinds()

  useEffect(() => {

    let timer = setInterval(() => {
      if (useBlinds.getState().scene === 'sine') {
        useBlinds.setState(draft => {
          resolveState(draft, Date.now() / 1000)

          for (let i = 0; i < 10; i++) {
            const n = Math.floor(Math.random() * N_WHITE)
            draft.white[n].target = scaleY(Math.random())
          }
        })
      }
    }, 1000)

    const unsubscribe = () => {
      clearTimeout(timer)
    }
    return unsubscribe
  }, [store])

  return store
}
