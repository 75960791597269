import { useCallback, useState } from 'react'
import { Box, Stack, Button, Group, Paper, Card } from '@mantine/core'
import { Text, SegmentedControl } from '@mantine/core'
import { Blind, useBlinds, useWatchScene, useWatchPositions } from '../model/blinds'

import { useScreenStore } from '../model/screen'

import { useNavigate } from 'react-router'

import { api } from '../model/api'

import ColorPanel from '../components/ColorPanel'

const SCENES = ['none', 'sine', 'sine2', 'random', 'line', 'vee']

const MODES = ['manual', 'auto', 'locked']

type ControlProps = {
  name: string,
  position?: number,
  onUp: () => void
  onDown: () => void
}

function Control({name, position, onUp, onDown}: ControlProps) {
  return <Card withBorder radius="md" p="md">
    <Card.Section withBorder inheritPadding py="sm">
      <Group position="apart">
        <Text size="md">{name}</Text>
        { position != null &&
          <Text size="sm">{Math.round(position * 100)}%</Text>
        }
      </Group>
    </Card.Section>
    <Group>
      <Button onClick={onUp}>Up</Button>
      <Button onClick={onDown}>Dn</Button>
    </Group>
  </Card>
}


type BlindControlProps = {
  i: number
  color: 'white' | 'black',
  blind: Blind
}

function BlindControl({i, color, blind}: BlindControlProps) {

  const name = `${color.toUpperCase()} ${i+1}`

  const onUp = useCallback(() => {
    api.post(`blinds/${color}/${i}/up`)
  }, [color, i])

  const onDown = useCallback(() => {
    api.post(`blinds/${color}/${i}/down`)
  }, [color, i])


  return <Control name={name} position={blind.position} onUp={onUp} onDown={onDown} />
}

type ColorControlProps = {
  color: 'white' | 'black',
}

function ColorControl({color}: ColorControlProps) {
  const name = `ALL ${color.toUpperCase()}`

  const onUp = useCallback(() => {
    api.post(`blinds/${color}/up`)
  }, [color])

  const onDown = useCallback(() => {
    api.post(`blinds/${color}/down`)
  }, [color])


  return <Control name={name} onUp={onUp} onDown={onDown} />
}

function Debug() {
  const navigate = useNavigate()
  const resetScreen = useScreenStore(state => state.done)

  const goToSurvey = useCallback(() => {
    resetScreen()
    navigate('/')
  }, [])

  const whiteBlinds = useBlinds(state => state.white)
  const blackBlinds = useBlinds(state => state.black)
  const scene = useBlinds(state => state.scene)
  const setScene = useBlinds(state => state.setScene)

  const mode = useBlinds(state => state.mode)
  const setMode = useBlinds(state => state.setMode)

  const [colorPanelOpen, setColorPanelOpen] = useState(false)


  useWatchScene()
  useWatchPositions('white', 5000)
  useWatchPositions('black', 5000)
  // const moveFaux = useBlinds(state => state.moveFaux)

  return <Paper py="64px" px="64px" style={{width: '100%', height: '100%', overflowY: 'scroll'}}>
    <Stack justify="flex-start" style={{height: '100%'}}>
      <Group position="left">
        <Button size="md" onClick={goToSurvey}>&lt;&nbsp;&nbsp; Back to survey</Button>
      </Group>
      <Group align="end">
          <Button size="md" onClick={() => api.post('blinds/reset')}>Reset</Button>

          <Button size="md" onClick={() => api.post('blinds/learn')}>Recalibrate</Button>

        <Box ml="lg">
        <Text size="sm">Mode:</Text>
        <SegmentedControl size="md" value={mode} onChange={setMode} data={MODES} />
        </Box>

        <Box ml="lg">
        <Text size="sm">Scene:</Text>
        <SegmentedControl size="md" value={scene} onChange={setScene} data={SCENES} />
        </Box>


{/*        <Box ml="lg">
        <Text size="sm">Colors:</Text>
        <Button onClick={() => setColorPanelOpen(true)}>Change colors</Button>
        </Box>
*/}
      </Group>

      <Group mt="lg">
        <ColorControl color="white" />
        <ColorControl color="black" />
      </Group>

      <Group mt="lg">
        {blackBlinds.map((blind, i) =>
          <BlindControl key={`b${i}`} color='black' i={i} blind={blind} />
        )}
      </Group>

      <Group mt="lg">
        {whiteBlinds.map((blind, i) =>
          <BlindControl key={`w${i}`} color='white' i={i} blind={blind} />
        )}
      </Group>


      <Box sx={{flex: "1 1 auto"}} />
    </Stack>

    { colorPanelOpen && <ColorPanel onClose={() => setColorPanelOpen(false)}/> }
  </Paper>
}

export default Debug
