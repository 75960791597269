import { useRef, useState, useEffect, useCallback, MutableRefObject } from 'react'
import { Box, Stack, Paper, TextInput, Title } from '@mantine/core'
import { useIdle } from '@mantine/hooks'

import { useScreenStore } from '../model/screen'
import { useWatchScene, useBlinds } from '../model/blinds'

import { useNavigate } from 'react-router'

import { Keyboard } from '../components/Keyboard'


const LOCK_IDLE_TIMEOUT = 30 * 1000

type PinLockProps = {
  locked: boolean,
  onDone?: (pin: string) => void,
  onCancel?: () => void,
}

const numericLayout = {
  default: ["1 2 3", "4 5 6", "7 8 9", "{cancel} 0 {enter}",],
  shift: ["! / #", "$ % ^", "& * (", "{cancel} ) +"]
}

function PinLock({onDone, onCancel, locked}: PinLockProps) {
    const [pin, setPin] = useState('')

    const onEnter = () => {
      onDone?.(pin)
      setPin('')
    }

    const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      setPin(ev.target.value)
    }

    return (<>
      <Box w="480px" sx={{color: "black"}}>
      <TextInput
        type="password"
        styles={{input: {textAlign: 'center'}}}
        value={pin}
        inputMode="none"
        onChange={onChange}
        // readOnly
        size="xl"
      />

      <Keyboard
        onChange={setPin}
        onEnter={onEnter}
        onCancel={onCancel}
        value={pin}
        layout={numericLayout}
        theme={"hg-theme-default hg-layout-numeric"}
        display={{
          '{bksp}': '⌫',
          '{cancel}': locked ? '[LOCKED]' : 'BACK',
          '{enter}': '⏎',
        }}
      />

      </Box>

      </>
    )
}


function Debug() {
  const navigate = useNavigate()
  const goToDebug = useCallback(() => navigate('/'), [])

  const resetScreen = useScreenStore(state => state.done)
  const blindsMode = useBlinds((state) => state.mode)
  const locked = blindsMode === 'locked'

  useWatchScene(5000)

  const idle = useIdle(LOCK_IDLE_TIMEOUT, { initialState: false })

  const goToSurvey = useCallback(() => {
    if (locked) return
    resetScreen()
    navigate('/')
  }, [])

  useEffect(() => {
    if (idle && !locked) goToSurvey()
  }, [idle, locked])

  const onDone = (pin: string) => {
    if (pin === '6634') navigate('/debug')
  }

  return <Paper py="64px" px="64px" style={{width: '100%', height: '100%', overflowY: 'scroll'}}>
    <Stack justify="center" align="center" style={{height: '100%'}}>
      <Stack>
        <Title size="h2">Enter Code</Title>
        <PinLock locked={locked} onDone={onDone} onCancel={goToSurvey} />
      </Stack>
    </Stack>

  </Paper>
}

export default Debug
