import { useEffect } from 'react'

import { useScreenStore } from '../model/screen'
import { api } from './api'

const UPDATE_CHECK_INTERVAL = 60*1000

const isSafeScreen = () => {
  const screen = useScreenStore.getState().screen
  return (screen === 'home') || (screen === 'consent')
}

const getIndexSrc = (el: Element | DocumentFragment): string | null => {
  const script = el.querySelector<HTMLScriptElement>('script[src^="/index"]')
  if (!script?.src) return null

  return new URL(script.src, document.baseURI).pathname
}

const getDoc = async (url: string): Promise<DocumentFragment> => {
  const html = await fetch(url).then(x => x.text())
  const template = document.createElement('template')
  template.innerHTML = html

  return template.content
}

const checkUpdates = async () => {
  const mySrc = getIndexSrc(document.documentElement)

  if (!mySrc) return

  const theirSrc = getIndexSrc(await getDoc('/'))

  if (mySrc !== theirSrc && isSafeScreen()) {
    console.log('mySrc   ', mySrc)
    console.log('theirSrc', theirSrc)
    console.log('Triggered update')
    window.location.reload()
  }
}

export const useUpdates = () => {
  useEffect(() => {
    const timer = setInterval(checkUpdates, UPDATE_CHECK_INTERVAL)
    console.log('Listening for updates', timer)

    return () => {
      console.log('Unlistening for updates', timer)
      clearInterval(timer)
    }
  }, [])
}
